

#rvp-wrapper{
	position: relative;
	left:0;
	max-width:900px;
	min-height:300px;
	background: #111;
	font-family: Arial, Helvetica, sans-serif;
	top:0;
}
.rvp-wrapper-bottom{
	max-height: none!important;
}
#rvp-wrapper:after {
    padding-top: calc(56.25% + 97px);/* + playlist height */
    display: block;
    content: '';
}



.rvp-player-holder,
.rvp-media-holder,
.rvp-video-holder,
.rvp-youtube-holder,
.rvp-vimeo-holder,
.rvp-iframe-holder{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow:hidden;
	display: none;
}
.rvp-player-holder,
.rvp-holder-visible{
	overflow: visible!important;
}
.rvp-player-holder-bottom{
    width: 100%!important;
}



.rvp-media{
	max-width:none!important;
	position: absolute;
	opacity: 0; 
	width: 100%;
	height: 100%;
	left:0;
	top:0;
}


.rvp-logo{
	position: absolute;
	top: 15px;
	left: 15px;
	opacity: 0.7;
}

.rvp-player-loader {
	position:absolute;
	top:50%;
	left:50%;
    width: 40px;
    height: 40px;
    margin-left:-20px;
	margin-top:-20px;
    background-color: #eee;
    -webkit-animation: rvp_preloader 1.2s infinite ease-in-out;
    animation: rvp_preloader 1.2s infinite ease-in-out;
}
@-webkit-keyframes rvp_preloader {
    0% { -webkit-transform: perspective(120px) }
    50% { -webkit-transform: perspective(120px) rotateY(180deg) }
    100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}
@keyframes rvp_preloader {
    0% { 
	    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
    } 50% { 
	    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
    } 100% { 
	    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}










#rvp-playlist-list{
	display:none;
}



.rvp-playlist-holder{
	position:absolute;
	left:0;
	bottom:0;
	width:100%;
	height:107px;
	background:#111;
	overflow: hidden;
	display: none;
}
.rvp-playlist-inner{
	position:absolute;
	top:0;
	left:0;
	height:100%;
	width:100%;
}
.rvp-playlist-item{
	position:relative;
	top:0;
	left:0;
	float: left;
	width:300px;
	height:90px;
	background:#181818;
	margin-right:5px;
	overflow:hidden;
	cursor: pointer;
}
.rvp-playlist-item:last-child{
	margin-right:0;
}
.rvp-playlist-item-selected{
	background:#333;
}
.rvp-playlist-thumb {
	position:relative;
	top:8px;
	margin-left:8px;
	width:120px;
	height:68px;
	background:#222;
	border: 3px solid #ddd;
	float:left;
	overflow:hidden;
}
.rvp-thumbimg{
	position:absolute;
	top:0;
	left:0;
	display:block;
	height:100%;
	width:auto;
	min-width: 100%;
	max-width: none;
	opacity: 0;
	-webkit-backface-visibility: hidden;
}
.rvp-playlist-info {
	position:relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);	
	left:7px;
	margin-right:10px;
	text-align: left;
	line-height: normal;
	height:80px;
	overflow: hidden;
}
.rvp-playlist-title {
	color:#c66;
	font-size:13px;
	font-weight: 600;
}
.rvp-playlist-description{
	color:#ffffff;
	font-size:11px;
	display: block;
	margin-top: 5px;
}









.rvp-hidden {
	opacity: 0; 
	transition: opacity 300ms ease-out;
}
.rvp-visible {
	opacity: 1;
	transition: opacity 500ms ease-in;
}
.rvp-force-hidden{
	position:absolute!important;
	left:-10000px!important;
}
[dir=rtl] .rvp-force-hidden {
    right:-10000px!important;
}



#rvp-wrapper .mCSB_inside > .mCSB_container {
    margin-right: 20px;
}
.rvp-mCSB_full{/* hide scroll area when no scroll */
	margin-right: 0!important;
}


